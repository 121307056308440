import React from "react"
import "../../scss/footer.scss"
import { Link } from "gatsby"

class SecondaryFooter extends React.Component {
  render() {
    return (
      <div className="footer-bottom d-print-none">
        <p className="copyright-statement">
          ©2024{" "}
          <a
            href="https://www.tysonfoods.com/"
            target="_blank"
            rel="noreferrer"
          >
            Tyson Foods
          </a>
          , Inc. Trademarks and registered trademarks are owned by{" "}
          <a
            href="https://www.tysonfoods.com/"
            target="_blank"
            rel="noreferrer"
          >
            Tyson Foods
          </a>
          , Inc. or its subsidiaries.{" "}
          <Link to="/privacy-policy" title="Privacy Policy">
            <span className="general-link-container">Privacy Policy</span>
          </Link>
        </p>
      </div>
    )
  }
}

export default SecondaryFooter
