import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { Collapse, Navbar, Nav, NavItem, Container } from "reactstrap";
import { throttle } from "lodash";
import logo from "../../img/WeaverHeader.jpg";
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isMenuOpen: [],
      active: false,
      writeReview: false,
      isSearch: false,
    }
  }

  navItems = () => {
    return [
      { title: "Home", url: "/" },
      { title: "About", url: "/about/" },
      { title: "Products", url: "/products/" },
      { title: "Menu ideas", url: "/menu-ideas/" },
    ]
  }

  writeReview = () => {
    //const div = document.createElement("div")
    //ReactDOM.render(<BVProductPicker />, div)
    //document.body.appendChild(div)
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch })
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  checkYPosition = () => {
    this.setState({
      active: window.pageYOffset > 0 ? true : false,
    })
    this.scrolledClass = `${window.pageYOffset > 200 ? "scrolled" : ""}`
  }

  componentDidMount = () => {
    if (!this.props.solid) {
      this.checkYPosition()
      window.addEventListener("scroll", throttle(() => this.checkYPosition()))
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
          return (
            <div className="fixed-top">
              <Navbar
                id="navigation"
                role="navigation"
                expand="xl"
                className={`p-0`}
              >
                <Container className={"pl-0 pr-0"}>
                  <Link
                    to="/"
                    title={data.site.siteMetadata.title}
                    className="d-block nav-logo"
                  >
                    <img
                      className=""
                      src={logo}
                      alt={data.site.siteMetadata.title}
                    />
                  </Link>

                  <Link
                    to="/"
                    title={data.site.siteMetadata.title}
                    className="d-block nav-logo navbar-brand"
                  >
                    <img
                      className={`mobile`}
                      src={logo}
                      alt={data.site.siteMetadata.title}
                    />
                  </Link>
                  <Collapse className={`justify-content-end ${this.state.isOpen ? "show" : ""}`} navbar>
                    <Nav className={``} navbar>
                      {this.navItems().map((navItem, i) => {
                        return (
                          <NavItem
                            key={i}
                            className={`d-flex ${
                              this.state.isMenuOpen[navItem.title + i]
                                ? "is-active"
                                : ""
                            }`}
                          >
                            <Link
                              to={navItem.url}
                              getProps={({ isPartiallyCurrent }) => {
                                let linkClass = `my-auto nav-link`
                                linkClass += isPartiallyCurrent ? "" : ""
                                linkClass += navItem.isDisabled
                                  ? " nav-link--disabled"
                                  : ""
                                return { className: linkClass }
                              }}
                            >
                              {navItem.title}
                            </Link>
                          </NavItem>
                        )
                      })}
                    </Nav>
                  </Collapse>
                  <button
                    className={
                      "btn btn-none hamburger hamburger--spin navbar-toggler" +
                      (this.state.isOpen ? " is-active" : "")
                    }
                    onClick={this.toggle}
                    aria-controls="primary-menu"
                    aria-expanded={this.state.isOpen}
                    aria-label="Toggle navigation"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </Container>
              </Navbar>
            </div>
          )
        }}
      />
    )
  }
}

export default Header
