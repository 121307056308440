import { first, filter } from "lodash"

const getMetatag = (metatags, tagName) => {
  const tagContent = first(
    filter(metatags, metatag => {
      return (
        metatag.attributes.name === tagName &&
        metatag.attributes.content.length > 0
      )
    })
  )

  return tagContent ? tagContent.attributes.content : ""
}

export default getMetatag

export const getSwiftypeMetaTag = (fieldName, fieldType, content) => {
  if (fieldName && fieldType && content) {
    return {
      name: fieldName,
      className: "swiftype",
      "data-type": fieldType,
      content: content,
    }
  }

  return {}
}
export const getSwiftypeMetaTagForTaxonomyTerms = (terms, fieldName) => {
  if (terms && terms.length > 0) {
    return terms.map(term => {
      return getSwiftypeMetaTag(fieldName, "string", term.name)
    })
  }
  return []
}
