import React from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import SecondaryFooter from "./SecondaryFooter";
import { Container, Row } from "reactstrap";
import { upperFirst } from "lodash";
import { getSwiftypeMetaTag } from "../../utilities";
import "../../scss/global.scss";

const TemplateWrapper = ({
  pageTitle,
  metaTitle,
  metaDescription,
  metaKeywords,
  children,
  googleSearchSchema
}) => {

  const title = metaTitle ? metaTitle : pageTitle ? upperFirst(pageTitle) : ""

  return (
    <React.Fragment>
    <Helmet
      title={`Weaver Chicken ${title ? " | " + upperFirst(title) : ""}`}
      meta={[
        { name: "description", content: metaDescription },
        { name: "keywords", content: metaKeywords },
        { name: "theme-color", content: "#ee5236" },
        {
          ...getSwiftypeMetaTag("type", "enum", "page"),
        },
        {
          ...getSwiftypeMetaTag("title", "string", title),
        },
        {
          ...getSwiftypeMetaTag("body", "string", metaDescription || ""),
        },
      ]}
    >
        {googleSearchSchema && (
            <script id="googleSearchSchema" type="application/ld+json">
                {JSON.stringify(googleSearchSchema)}
            </script>
        )}
    </Helmet>
    <Header />
    <main>
      <Container fluid={true}>
        <Row>{children}</Row>
      </Container>
    </main>
    <SecondaryFooter className={"d-print-none"} />
  </React.Fragment>
  )
}

export default TemplateWrapper
